export default [
  {
    path: "/marketplace/admin/ads-banner",
    name: "AdminAdsBanner",
    component: () => import(/* webpackChunkName: "adminadsbanner" */ "@/views/private/admin/banner/BannerView"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/application-setting",
    name: "ApplicationSetting",
    component: () => import(/* webpackChunkName: "application-setting" */ "@/views/private/admin/tools/ApplicationSetting"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/cities",
    name: "AdminCitiesView",
    component: () => import(/* webpackChunkName: "admincitiesview" */ "@/views/private/admin/cities/CitiesView"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/community-impact-data",
    name: "AdminCommunityImpactData",
    component: () => import(/* webpackChunkName: "admincommunityimpactdata" */ "@/views/private/admin/impact/ImpactView"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/contact-us/detail/:id",
    name: "AdminContactUsDetail",
    component: () => import(/* webpackChunkName: "admincontactusdetail" */ "@/views/private/admin/contactus/ContactusEntry"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/contact-us",
    name: "AdminContactUsList",
    component: () => import(/* webpackChunkName: "admincontactuslist" */ "@/views/private/admin/contactus/ContactusView"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/districts",
    name: "AdminDistrictsView",
    component: () => import(/* webpackChunkName: "admindistrictsview" */ "@/views/private/admin/districts/DistrictsView"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/dynamic-pages",
    name: "AdminDynamicPageList",
    component: () => import(/* webpackChunkName: "admindynamicpagelist" */ "@/views/private/admin/pages/DynamicPageList"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/joined-career-representatives",
    name: "AdminJoinedView",
    component: () => import(/* webpackChunkName: "adminjoinedview" */ "@/views/private/admin/joined/JoinedView.vue"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/manage-events",
    name: "AdminEventList",
    component: () => import(/* webpackChunkName: "admineventlist" */ "@/views/private/admin/events/EventList"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/manage-staff",
    name: "AdminManageStaff",
    component: () => import(/* webpackChunkName: "adminmanagestaffs" */ "@/views/private/admin/users/ManageStaffView"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/new-business",
    name: "AdminBusiness",
    component: () => import(/* webpackChunkName: "adminbusiness" */ "@/views/private/admin/NewBusiness"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/reported-bugs",
    name: "AdminReportedBugs",
    component: () => import(/* webpackChunkName: "adminreportedbugs" */ "@/views/private/admin/bugs/ReportedBugs"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/resource-page/edit/:id",
    name: "AdminResourcePageEdit",
    component: () => import(/* webpackChunkName: "adminresourcepageedit" */ "@/views/private/admin/resources/PageEdit"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/resource-page/list",
    name: "AdminResourcePageList",
    component: () => import(/* webpackChunkName: "adminresourcepagelist" */ "@/views/private/admin/resources/ResourceView"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/resource-page/new",
    name: "AdminResourcePageNew",
    component: () => import(/* webpackChunkName: "adminresourcepagenew" */ "@/views/private/admin/resources/PageNew"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/schools",
    name: "AdminSchoolView",
    component: () => import(/* webpackChunkName: "adminschoolview" */ "@/views/private/admin/schools/SchoolsView"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/subscribers",
    name: "AdminSubscribersView",
    component: () => import(/* webpackChunkName: "adminsubscribersview" */ "@/views/private/admin/SubscribersView"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/tools",
    name: "AdminTools",
    component: () => import(/* webpackChunkName: "admintools" */ "@/views/private/admin/tools/ToolsView"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/users",
    name: "AdminUserView",
    component: () => import(/* webpackChunkName: "adminusersview" */ "@/views/private/admin/users/UserView"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/admin/version-updates",
    name: "AdminVersionUpdates",
    component: () => import(/* webpackChunkName: "adminversionupdates" */ "@/views/private/admin/version/VersionUpdates"),
    meta: {
      adminOnly: true,
      navbar: "basic",
      requiresAuth: true
    }
  },
];