import axios from "axios"

const getErrCode = (errno, note = "unknown") => {
  const message = {
    2:   "Credential is incorrect or permission denied",
    3:   "You do not have the permission to " + note,
    4:   note + " has already been registered as a user",
    5:   "The end date cannot be earlier than the start date",
    6:   "The " + note + " you're seeking for, does not exists. Please refresh the web page to see latest records",
    7:   "All other " + note + " have been removed",
    8:   "Form fields cannot be empty or invalid",
    9:   "Password does not match",
    10:  note + " has been re-sent",
    11:  "You have already " + note,
    12:  "Your account has been activated",
    13:  "Please " + note + " first before you proceed this action",
    14:  "New password has been sent to your email account if the email address exists in the database",
    15:   note + " cannot be the same",
    16:   note + " cannot be different",
    17:  "The system failed to send " + note + " email notification",
    18:  "Your confirmation email has been resent",
    19:  "The " + note + " already exists in the database",
    20:  "You have already created maximum records allowed",
    21:  "Your " + note + " has been sent to BEA administrators",
    22:  "The system has failed to send your message due to issues of your recipient(s)",
    23:  "The invitation email has been resent",
    24:  "Logged " + note + " successfully",
    25:  "The sysmte cannot delete the selected " + note + " record due to other data related to this record exist",
    26:  "reCAPTCHA has failed",
    100: "New " + note + " has been added",
    101: note + " has been updated",
    102: note + " has been deleted",
    103: note + " is invalid",
    104: "You have confirm to " + note,
    105: "You have declined to " + note,
    106: "You have set the status of " + note,
    107: "Nothing has changed",
    108: "CCL MARKETPLACE Administrators have received your request for assistance. Meanwhile you can access your draft within the opportunity list under your business",
    109: "You have agreed to " + note,
    110: note + " has been successfully uploaded",
    111: note + " has failed to be uploaded to the server",
    112: "Success!  An email explains the next step has been sent to your address of record",
    9995:note + " function is currently disabled by the system administrator",
    9996:"This function you are looking for is under-construction",
    9997:"Oops, something went wrong",
    9998:"Exception: Unknown System API name",
    9999:"Exception: Unknown function name",
  };
  if (message[errno]) {
    return message[errno] + ".";
  }
  else {
    return message[9996];
  }
}

// generate error message from error code
export function ajaxGenErrCode(err) {
  let msg = "";
  if (err.errno > 0) {
    msg = getErrCode(err.errno, err.note);
  }
  else if (err.errno == -1) {
    msg = err.errmsg + ".";
  }
  else {
    msg = getErrCode(1, "");
  }
  return msg;
}

export function ajaxErrShow(err) {
  if (typeof err.status === "undefined") {
    err.status = "danger";
  }
  return {
    message: ajaxGenErrCode(err),
    status: err.status
  }
}

// axios ajax call
export function ajaxCall(val, callback = false) {
  if (typeof axios !== "undefined") {
    const token = localStorage.getItem("beaToken");
    const bear = {
      headers: { Authorization: `Bearer ${token}` }
    }
    axios.post("/api/" + val.url, val.data, bear)
    .then(response => {
      if (+response.status >= 400) {
        callback({
          errmsg: response.statusText,
          errno: -1,
          status: "danger"
        })
      }
      else {
        response.data = ajaxCleanse(response.data);
        if (response.data === null) {
          console.warn({
            errmsg: "Nothing returned from the server",
            errno: -1
          });
        }
        if (callback) {
          callback(response.data);
        }
      }
    })
  }
  else {
    console.error("Axios not found!!");
    return
  }
}

export function ajaxAuthData(data, auth) {
  data.uid = auth.uid;
  data.user = auth.user;
  return data;
}

/*** convert json from string format to object ***/
export function ajaxCleanse(val) {
  if (typeof val === "string") {
    val = JSON.parse(val)
  }
  return val;
}

/*** compare returning error code with specified codes array ***/
export function ajaxCompare(errno, code) {
  let flag = false;
  for (let i = 0; i < code.length; i++) {
    if (errno === code[i]) {
      flag = true;
      break;
    }
  }
  return flag;
}