<template>
  <metainfo>
    <template #title="{ content }">
      {{ content ? `${content} | ${metaTitle}` : `${metaTitle}` }}
    </template>
  </metainfo>
  <NavBar />
  <div v-if="getUser && isInternal" class="main-content" role="main">
    <SideMenu v-if="isInternal" />
    <router-view
      :key="$route.fullPath"
      class="main-content-wrapper main-sidebar-anime"
      :class="isToggle"
    />
    <!-- <Message /> -->
  </div>
  <div v-else class="public-image-section">
    <router-view v-slot="{ Component }">
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <FooterView />
  <LoadingView v-if="isLoadingPage" />
  <VersionAlert v-if="!isLoadingVersion && appVersion !== getLocalVersion" :version="appVersion" />
</template>

<script>
/***
 * vue components/libraries
 */
import { mapGetters, mapState } from "vuex"
import FooterView from "@/components/static/FooterView"
import LoadingView from "@/components/static/LoadingView"
import NavBar from "@/components/static/NavBar"
import SideMenu from "@/components/static/SideMenu"
import VersionAlert from "@/components/static/VersionAlert"
/***
 * custom libraries
 */
import { ajaxCall, ajaxCompare } from "@/utils/ajax";
import { useMeta } from "vue-meta";

export default {
  name: "App",
  components: {
    FooterView,
    LoadingView,
    NavBar,
    SideMenu,
    VersionAlert
  },
  setup() {
    useMeta({
      title: "Welcome",
      htmlAttrs: { lang: 'en', amp: true },
      link: [
        { rel: "canonical", href: `${process.env.VUE_APP_BASEURL}` }
      ],
      meta: [{
        vmid: "description",
        name: "description",
        content: "Career Exploration and Training. Connecting K-12 Students' Talents, Interests & Aptitudes to the World of Work"
      }]
    })
  },
  data() {
    return {
      metaTitle: "Business and Education Alliance",
      isLoadingVersion: true
    }
  },
  computed: {
    ...mapGetters(["getUser"]),
    ...mapGetters("show", ["isToggle"]),
    ...mapState({
      appVersion: state => state.appVersion,
      isLoadingPage: state => state.show.isLoadingPage,
    }),
    isInternal() {
      return this.$store.state.isInternal
    },
    getLocalVersion() {
      return localStorage.getItem("curVersion") ?? "0.0.0"
    }
  },
  created() {
    this.getVersion()
  },
  methods: {
    getVersion() {
      ajaxCall({
        data: {
          api: "app",
          method: "GetVer"
        },
        url: "api"
      }, result => {
        if (ajaxCompare(result.errno, [1])) {
          if (result.errmsg) {
            this.$store.commit("UpdBaseValue", {
              cat: "appVersion",
              value: `${result.errmsg.major}.${result.errmsg.minor}.${result.errmsg.bug}`
            })
          }
          this.isLoadingVersion = false
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "bulma";
@import "~bulma-tooltip";
@import "@/assets/style/main.scss";
</style>
