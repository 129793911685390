import { createStore } from "vuex";
import limits from "./modules/limits";
import lists from "./modules/lists";
import notify from "./modules/notify";
import show from "./modules/show";
import theme from "./modules/theme"
import { profileState } from "@/utils/user";

export default createStore({
  state: {
    appVersion: "0.0.0",
    isInternal: false,
    Profile: {
      basic: {
        errmsg: {},
        errno: -1
      }
    },
    ProfileSections: [
      "cw", "ci", "exp", "act", "accp", "cert", "skill", "prsty", "comp", "appls"
    ],
    TinyConfig: {
      license_key: 'gpl',
      browser_spellcheck: true,
      contextmenu: "link image table spellchecker",
      contextmenu_never_use_native: true,
      height: "500px",
      image_caption: true,
      image_advtab: true,
      menubar: "edit insert view format table tools",
      paste_data_images:true,
      plugins: [
        "anchor", "autolink", "code", "codesample", "fullscreen", "help", "image", "insertdatetime", "link", "media", "lists", "media", "nonbreaking", "preview", "table", "wordcount"
      ],
      resize: false,
      theme: "silver",
      toolbar: "undo redo | preview fullscreen | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor",
    }
  },
  getters: {
    /***
     * get all signed in user's priviledge category
     */
    getProfileRanks: state => {
      let temp = [];
      if (state.Profile && state.Profile.ranks  && +state.Profile.ranks.errno === 1) {
        //for (let value of state.Profile.ranks.errmsg) {
        for (const key in state.Profile.ranks.errmsg) {
          temp.push(state.Profile.ranks.errmsg[key].name)
        }
      }
      return temp
    },
    /***
     * get user account from profile
     */
    getUser: state => {
      let temp = null;
      if (state.Profile && state.Profile.basic && +state.Profile.basic.errno === 1) {
        temp = state.Profile.basic.errmsg.email
      }
      return temp
    },
    /***
     * get user full name
     */
    getUserFullName: state => {
      let temp = "";
      if (state.Profile && state.Profile.basic && +state.Profile.basic.errno === 1) {
        temp = `${state.Profile.basic.errmsg.fname} ${state.Profile.basic.errmsg.lname}`
      }
      return temp
    },
    /***
     * get user profile completion status
     * 0: record, 1: register, 2: activate, 3: basic, 4: advanced 
     */
    getUserProfComplStatus: state => {
      if (!state.Profile) {
        return 1
      }
      else {
        return profileState(+state.Profile.basic.errmsg.profile, +state.Profile.basic.errmsg.bas, +state.Profile.basic.errmsg.adv)
      }
    },
    /***
     * is an ccl marketplace admin account
     */
    isAdm: (state, getters) => {
      return getters.isSad || getters.isSup || false
    },
    /***
     * is a business user account
     */
    isBiz: (state, getters) => {
      return getters.isAdm || getters.isRep || false
    },
    /***
     * is a education user account
     */
    isCnt: (state, getters) => {
      return getters.isDwm || getters.isSwa || getters.isStf || false
    },
    /***
     * is a district admin account
     */
    isDwm: (state, getters) => {
      return (getters.getProfileRanks.indexOf("dwm") > -1) ?  true : false
    },
    /***
     * is an event requester account
     */
    isEvt: (state, getters) => {
      return getters.isCnt || getters.isRep || false
    },
    /***
     * is an internal user account
     */
    isInt: (state, getters) => {
      return getters.isSad || getters.isCnt || false
    },
    /***
     * is an IT administrator account
     */
    isMis: (state, getters) => {
      return (getters.getProfileRanks.indexOf("mis") > -1) ? true : false
    },
    /***
     * is a management level user account
     */
    isMng: (state, getters) => {
      return getters.isSad || getters.isDwm || getters.isSwa || false
    },
    /***
     * is a parent account
     */
    isPar: (state, getters) => {
      return (getters.getProfileRanks.indexOf("par") > -1) ? true : false
    },
    /***
     * is a business representative account
     */
    isRep: (state, getters) => {
      return (getters.getProfileRanks.indexOf("rep") > -1) ?  true : false
    },
    /***
     * is a teacher, school staff, advisor account
     */
    isStf: (state, getters) => {
      return (getters.getProfileRanks.indexOf("stf") > -1) ?  true : false
    },
    /***
     * is a student account
     */
    isStu: (state, getters) => {
      return (getters.getProfileRanks.indexOf("stu") > -1) ?  true : false
    },
    /***
     * is a bea staff user account
     */
    isSup: (state, getters) => {
      return (getters.getProfileRanks.indexOf("sup") > -1) ?  true : false
    },
    /***
     * is a school wbl admin account
     */
    isSwa: (state, getters) => {
      return (getters.getProfileRanks.indexOf("swa") > -1) ?  true : false
    },
    /***
     * is an site adminsitrator account
     */
    isSad: (state, getters) => {
      return (getters.getProfileRanks.indexOf("sad") > -1) ? true : false
    },
  },
  mutations: {
    UpdBaseValue(state, data) {
      state[data.cat] = data.value
    },
    UpdProfileBasic(state, data) {
      state.Profile.basic = data
    }
  },
  actions: {
  },
  modules: {
    limits,
    lists,
    notify,
    show,
    theme
  }
})
