export default function browserDetect() {
  const dataBrowser = [
    { string: navigator.userAgent, subString: "Edg", identity: "IE-Edge", versionSearch: "Edg" },
    { string: navigator.userAgent, subString: "MSIE", identity: "Explorer", versionSearch: "MSIE" },
    { string: navigator.userAgent, subString: "Chrome", identity: "Chrome" },
    { string: navigator.userAgent, subString: "OmniWeb", versionSearch: "OmniWeb/", identity: "OmniWeb" },
    { string: navigator.userAgent, subString: "Firefox", identity: "Firefox" },
    { string: navigator.userAgent, subString: "Safari", identity: "Safari", versionSearch: "Version" },
    { string: navigator.userAgent, subString: "Opera", identity: "Opera", versionSearch: "Version" },
    { string: navigator.userAgent, subString: "iCab", identity: "iCab" },
    { string: navigator.userAgent, subString: "Konqueror", identity: "Konqueror" },
    { string: navigator.userAgent, subString: "Netscape", identity: "Netscape" },
    { string: navigator.userAgent, subString: "Mozilla", identity: "Mozilla", versionSearch: "rv" }
  ];

  let versionSearchString = "";

  const searchString = (data) => {
    for (let i = 0; i < data.length; i++) {
      const dataString = data[i].string;
      versionSearchString = data[i].versionSearch || data[i].identity;
      if (dataString && dataString.indexOf(data[i].subString) !== -1) {
        return data[i].identity;
      }
    }
  };

  const searchVersion = (dataString) => {
    const index = dataString.indexOf(versionSearchString);
    if (index === -1) return;
    return parseFloat(dataString.substring(index + versionSearchString.length + 1));
  };

  let browser = searchString(dataBrowser) || "An Unknown Browser";
  let version = searchVersion(navigator.userAgent) || "An Unknown Version";

  return {
    Browser: browser,
    Version: version
  };
}
