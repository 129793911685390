const state = () => ({
  ci: 3,
  comp: 3,
  lists: 50,
  maxChar: 255,
  oppcode: 200,
  oppdesc: 500,
  oppsrc: 250,
  oppsummary: 1000,
  opptext: 1500,
  passwd: 10,
  title: 100
})


export default {
  namespaced: true,
  state
}
