import browserDetect from "@/utils/browser";

/***
 * display account status
 */
export const acctStatus = (data) => {
  if (+data === 0) {
    return {
      color: "danger",
      icon: "frown",
      text: "This account has not been registered."
    }
  }
  else if (+data === 1) {
    return {
      color: "warning",
      icon: "frown",
      text: "This account has been registered, but not activated."
    }
  }
  else {
    return {
      color: "success",
      icon: "grin-beam",
      text: "This account has been activated"
    }
  }
}
/***
 * check user is an editor
 * listPoc: business/opportuntiy's point of contact
 * isRep: logged in user is a business representative
 * self: logged in user's email
 * isSad: logged in user is a site admin
 */
export const checkEditor = (self, isSad, isRep, listPoc, emailName = "email") => {
  // if logged in user is a site admin, then he/she is an editor
  if (isSad) {
    return true;
  }
  else if (!Array.isArray(listPoc) && isRep && self === listPoc) {
    return true;
  }
  return listPoc.some(item => item[emailName] === self);
}
/***
 * display consent status
 */
export const cnstStatus = (data) => {
  return (data != null) ?
    {
      color: "success",
      icon: "grin-beam"
    } :
    {
      color: "danger",
      icon: "frown"
    }
}
/***
 * check user has selected ranking class
 */
export const isRankof = (data, rank) => {
  let temp = false
  if (typeof data !== "undefined") {
    for (const key in data) {
      if (data[key].name === rank) {
        temp = true;
        break
      }
    }
  }
  return temp
}
/***
 * calculate the user registration steps
 * reg: register
 * bas: basic profile
 * adv: advanced profile
 */
export const profileState = (reg, bas, adv) => {
  if (reg >= 2) {
    reg = 2
  }
  if (reg < 2) {
    return reg
  }
  else {
    return reg + bas + adv
  }
}
/***
 * gather authentication data
 */
export const getAuthData = (data) => {
  const browser = browserDetect();
  data.browser = browser.Browser;
  data.account = localStorage.getItem("beaUser");
  return data
}
/***
 * get gender role name
 */
export const getGenderName = (data) => {
  const gender = [
    "Unknown",
    "Male",
    "Female"
  ];
  return gender[data] || gender[0]
}