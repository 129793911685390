export default [
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import(/* webpackChunkName: "aboutus" */ "@/views/public/static/AboutUs"),
    meta: {
      navbar: "stu"
    }
  },
  {
    path: "/activation",
    name: "UserActivation",
    component: () => import(/* webpackChunkName: "useractivaton" */ "@/views/public/UserActivation"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/become-career-representative",
    name: "BecomeCareerRepresentative",
    component: () => import(/* webpackChunkName: "becomecareerrepresentative" */ "@/views/public/business/BecomeCareerRepresentative"),
    meta: {
      navbar: "biz"
    }
  },
  {
    path: "/business/advisories",
    name: "BusinessAdvisories",
    component: () => import(/* webpackChunkName: "businessadvisories" */ "@/views/public/business/BusinessAdvisories"),
    meta: {
      navbar: "biz"
    }
  },
  {
    path: "/business/career-representatives",
    name: "CareerRepresentative",
    component: () => import(/* webpackChunkName: "careerrepresentative" */ "@/views/public/business/CareerRepresentative"),
    meta: {
      navbar: "biz"
    }
  },
  {
    path: "/business/partners",
    name: "BusinessPartners",
    component: () => import(/* webpackChunkName: "businesspartners" */ "@/views/public/business/BusinessPartners"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/business/business-resources",
    name: "BusinessResource",
    component: () => import(/* webpackChunkName: "businessresource" */ "@/views/public/business/BusinessResource"),
    meta: {
      navbar: "biz"
    }
  },
  {
    path: "/change-email",
    name: "ChangeEmail",
    component: () => import(/* webpackChunkName: "changeemail" */ "@/views/public/ChangeEmail"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/contact-us/:page",
    name: "ContactUs",
    component: () => import(/* webpackChunkName: "contact-us" */ "@/views/public/contactus/HomeView"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/contact-us",
    meta: {
      navbar: "basic"
    },
    redirect: to => {
      return {
        path: '/contact-us/leave-us-a-message'
      }
    },
  },
  {
    path: "/education/i-am-a-student",
    name: "IAmStudent",
    component: () => import(/* webpackChunkName: "iamstudent" */ "@/views/public/education/IAmStudent"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/education/education-resources",
    name: "EducationResource",
    component: () => import(/* webpackChunkName: "educationresource" */ "@/views/public/education/EducationResource"),
    meta: {
      navbar: "stu"
    }
  },
  {
    path: "/education/teachers-and-parents",
    name: "TeachersParents",
    component: () => import(/* webpackChunkName: "teachersparents" */ "@/views/public/education/TeachersParents"),
    meta: {
      navbar: "stu"
    }
  },
  {
    path: "/en/parent-guardian-work-based-learning-agreement",
    name: "ParentGuardianWBLAgreeEn",
    component: () => import(/* webpackChunkName: "parentguardianwblagreeen" */ "@/views/public/static/ParentGuardianWBLAgreeEn"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/en/work-based-learning-media-release",
    name: "WBLMediaRelease",
    component: () => import(/* webpackChunkName: "wblmediarelease" */ "@/views/public/static/WBLMediaReleaseEn"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/es/parent-guardian-work-based-learning-agreement",
    name: "ParentGuardianWBLAgreeEs",
    component: () => import(/* webpackChunkName: "parentguardianwblagreees" */ "@/views/public/static/ParentGuardianWBLAgreeEs"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/es/work-based-learning-media-release",
    name: "WBLMediaReleaseEs",
    component: () => import(/* webpackChunkName: "wblmediareleasees" */ "@/views/public/static/WBLMediaReleaseEs"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import(/* webpackChunkName: "forgotpassword" */ "@/views/public/ForgotPassword"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/frequently-asked-questions",
    name: "Faq",
    component: () => import(/* webpackChunkName: "faq" */ "@/views/public/FaqView"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/invitation",
    name: "StaffInvitation",
    component: () => import(/* webpackChunkName: "staffinvitation" */ "@/views/public/StaffInvitation"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/login",
    name: "LoginView",
    component: () => import(/* webpackChunkName: "loginform" */ "@/views/public/LoginView"),
    meta: {
      navbar: "stu"
    }
  },
  {
    path: "/ppbea-events",
    name: "ExternalEvents",
    component: () => import(/* webpackChunkName: "externalevents" */ "@/views/public/EventView"),
    meta: {
      navbar: "stu"
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicies",
    component: () => import(/* webpackChunkName: "loginform" */ "@/views/public/static/PrivacyPolicies"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/register",
    name: "Registeration",
    component: () => import(/* webpackChunkName: "register" */ "@/views/public/RegisterView"),
    meta: {
      navbar: "stu"
    }
  },
  {
    path: "/research-and-media",
    name: "ResearchMedia",
    component: () => import(/* webpackChunkName: "researchmedia" */ "@/views/public/ResearchMedia"),
    meta: {
      navbar: "biz"
    }
  },
  {
    path: "/student-work-based-learning-agreement",
    name: "StuWBLAgrmt",
    component: () => import(/* webpackChunkName: "stuwblagrmt" */ "@/views/public/static/StudentWBLAgreement"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/subscribe",
    name: "SubscribeNewsletter",
    component: () => import(/* webpackChunkName: "subscribenewslettert" */ "@/views/public/NewsLetter"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/subscription-center",
    name: "SubscriptionCenter",
    component: () => import(/* webpackChunkName: "subscriptioncenter" */ "@/views/public/SubscriptionCenter"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsConditions",
    component: () => import(/* webpackChunkName: "termsconditions" */ "@/views/public/static/TermsConditions"),
  },
  {
    path: "/version-updates",
    name: "VersionUpdates",
    component: () => import(/* webpackChunkName: "versionupdates" */ "@/views/public/VersionUpdates"),
    meta: {
      navbar: "basic"
    }
  },
  {
    path: "/work-based-learning-partnership-agreement",
    name: "WBLPtrAgrmt",
    component: () => import(/* webpackChunkName: "wblptragrmt" */ "@/views/public/static/WBLPartnershipAgreement")
  },
];