import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import HomeView from "@/views/public/HomeView.vue";
import adminRoutes from "./modules/admin";
import privateRoutes from "./modules/private";
import publicRoutes from "./modules/public";
/***
 * custom libraries
 */
import { ajaxCompare } from "@/utils/ajax";
import axios from "axios";
import { getAuthData } from "@/utils/user";
import { textCapFirst } from "@/utils/text"

const baseRoutes = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/AboutView.vue")
  }
];

const routes = baseRoutes.concat(adminRoutes, privateRoutes, publicRoutes);

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    else {
      return { top: 0 }
    }
  },
  routes
})

const updInternalStatus = (status) => {
  store.commit("UpdBaseValue", {
    cat: "isInternal",
    value: status
  })
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // getting local stored user email from localStorage
    const localStoredUser = window.localStorage.getItem("beaUser");
    const localStoredToken = window.localStorage.getItem("beaToken");
    if (localStoredUser === null || localStoredToken === null) {
      console.log("No saved user");
      next("/");
      return
    }
    const vuexStoredUser = store.getters.getUser;
    try {
      if (from.fullPath !== "/login") {
        let loadProfile = false;
        if (vuexStoredUser !== localStoredUser) {
          loadProfile = true;
        }
        const bear = {
          headers: { Authorization: `Bearer ${localStoredToken}` }
        };
        const requestData = getAuthData({
          api: "auth",
          method: "Check",
          profile: loadProfile
        });
        const response = await axios.post("/api/auth", requestData, bear);
        if (ajaxCompare(response.data.errno, [1])) {
          if (typeof response.data.profile !== "undefined") {
            store.commit("UpdBaseValue", {
              cat: "Profile",
              value: response.data.profile
            });
          }
          if (typeof response.data.notify !== "undefined") {
            for (let obj in response.data.notify) {
              if (ajaxCompare(response.data.notify[obj].errno, [1])) {
                store.commit(
                  "notify/updNotify",
                  {
                    cat: textCapFirst(obj),
                    value: response.data.notify[obj].errmsg.count
                  }
                )
              }
            }
          }
          updInternalStatus(true)
        }
        else {
          updInternalStatus(false)
          next("/");
          return;
        }
      }
    } catch (error) {
      console.error(error);
      next("/error");
      return;
    }
  }
  else {
    updInternalStatus(false)
  }
  /***
   * check if the page is admin only page
   */
  if (to.matched.some(record => record.meta.adminOnly)) {
    if (!store.getters.isSad) {
      next("/marketplace/permission-denied");
      return;
    }
  }
  /***
   * check navbar style
   */
  if (to.matched.some(record => record.meta.navbar)) {
    let style = to.meta.navbar;
    if (style === "basic") {
      style = ""
    }
    store.commit("theme/UpdTheme", {
      cat: "NavBar",
      value: style
    });
  }

  to.meta.previousRoute = from.path;
  next();
});

export default router
