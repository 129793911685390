<template>
  <a :class="isNavBar" @click="logOff">
    <font-awesome-icon v-if="location === 'sidebar'" class="fa-fw" icon="sign-out-alt" />
    <span :class="isSideBar">Sign Out</span>
  </a>
</template>

<script>
/***
 * custom libraries
 */
import { ajaxErrShow } from "@/utils/ajax";
/***
 * third-party libraries
 */
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "LogOff",
  props: {
    location: {
      type: String,
      default: "navbar"
    }
  },
  computed: {
    isInternal() {
      return this.$store.state.isInternal
    },
    /***
     * add css class "navbar-item" if it is the sign out link on the navbar
     */
    isNavBar() {
      return (this.location === "navbar") ? "navbar-item" : ""
    },
    /***
     * add css class "page" if it is the sign out link on the sidebar
     */
    isSideBar() {
      return (this.location === "sidebar") ? "page" : ""
    }
  },
  methods: {
    /***
     *  log off web portal
     */
    logOff() {
      const errMsg = ajaxErrShow({
        errno: 24,
        note: "off",
        status: "success"
      });
      createToast(errMsg.message, {
        showIcon: true,
        type: errMsg.status,
        position: "bottom-right"
      });
      this.$router.push("/").catch();
      localStorage.removeItem("beaUser");
      localStorage.removeItem("beaToken");
      this.$store.commit("UpdBaseValue", {
        cat: "Profile",
        value: {
          basic: {
            errmsg: {},
            errno: -1
          }
        }
      });
      this.$store.commit("theme/UpdTheme", {
        cat: "NavBar",
        value: ""
      })
    },
  }
}
</script>

<style scoped>
.page {
  font-family: "Bwstretch", sans-serif;
}
</style>
