<template>
  <aside class="main-sidebar main-sidebar-anime is-hidden-print" :class="isToggle">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">
      <!-- Sidebar Menu -->
      <ul class="sidebar-menu">
        <li class="header is-uppercase">
          Pages
        </li>
        <li class="page-link has-tooltip-right" data-tooltip="User Dashboard">
          <router-link title="Dashboard" to="/marketplace/dashboard">
            <font-awesome-icon class="fa-fw" icon="desktop" />
            <span class="page">Dashboard</span>
          </router-link>
        </li>
        <li class="page-link has-tooltip-right" data-tooltip="Manage User Profile Information">
          <router-link title="Profile" to="/marketplace/profile">
            <font-awesome-icon class="fa-fw" icon="user" />
            <span class="page">Profile</span>
          </router-link>
        </li>
        <li class="page-link has-tooltip-right" data-tooltip="Visit Connection Center">
          <router-link title="Connection Center" to="/tasks/active-task">
            <font-awesome-icon class="fa-fw" icon="tasks" />
            <span class="page">Connection Center</span>
          </router-link>
        </li>
        <li class="page-link has-tooltip-right" data-tooltip="Manage User Settings">
          <router-link title="User Settings" to="/marketplace/user-setting">
            <font-awesome-icon class="fa-fw" icon="cogs" />
            <span class="page">User Settings</span>
          </router-link>
        </li>
        <li class="page-link has-tooltip-right" :data-tooltip="'Browse Opportunities ' + appName + ' business partners provide'">
          <router-link title="Opportunities" to="/marketplace/opportunities">
            <font-awesome-icon class="fa-fw" icon="building" />
            <span class="page">Opportunities</span>
          </router-link>
        </li>
        <li class="page-link has-tooltip-right" data-tooltip="Calendar Events">
          <router-link title="Events" to="/marketplace/events">
            <font-awesome-icon class="fa-fw" icon="calendar-day" />
            <span class="page">Events</span>
          </router-link>
        </li>
        <li class="page-link has-tooltip-right" :data-tooltip="'Courses offered by ' + appName">
          <router-link to="/marketplace/learn">
            <font-awesome-icon class="fa-fw" icon="certificate" />
            <span class="page">Learn</span>
          </router-link>
        </li>
        <li class="page-link has-tooltip-right" data-tooltip="Resources Students can evaluate themseleves">
          <router-link to="/marketplace/resources">
            <font-awesome-icon class="fa-fw" icon="book-open" />
            <span class="page">Resources</span>
          </router-link>
        </li>
        <li v-if="!isStu" class="page-link has-tooltip-right" data-tooltip="Reports">
          <router-link title="Report" to="/marketplace/reports">
            <font-awesome-icon class="fa-fw" icon="scroll" />
            <span class="page">Reports</span>
          </router-link>
        </li>
        <li v-if="isInt" class="header is-uppercase">
          Management
        </li>
        <li v-if="isMis" class="page-link">
          <router-link title="Manamgement Information System Area" to="/admin/mis">
            <font-awesome-icon class="fa-fw" icon="microchip" />
            <span class="page">MIS Area</span>
          </router-link>
        </li>
        <li v-if="isSad" class="page-link has-tooltip-right" data-tooltip="Manage Application Settings">
          <router-link to="/marketplace/admin/application-setting">
            <font-awesome-icon class="fa-fw" icon="coins" />
            <span class="page">Application Setting</span>
          </router-link>
        </li>
        <li v-if="isSad" class="page-link has-tooltip-right" data-tooltip="Manage Contact Us Messages">
          <router-link to="/marketplace/admin/contact-us">
            <font-awesome-icon class="fa-fw" icon="envelope" />
            <span class="page">Contact Us</span>
          </router-link>
        </li>
        <li v-if="isSad" class="page-link has-tooltip-right" data-tooltip="Manage Events">
          <router-link to="/marketplace/admin/manage-events">
            <font-awesome-icon class="fa-fw" icon="calendar-day" />
            <span class="page">Manage Events</span>
          </router-link>
        </li>
        <li v-if="isSad" class="page-link has-tooltip-right" data-tooltip="Manage Staff">
          <router-link to="/marketplace/admin/manage-staff">
            <font-awesome-icon class="fa-fw" icon="users-cog" />
            <span class="page">Manage Staff</span>
          </router-link>
        </li>
        <li v-else-if="isDwm" class="page-link has-tooltip-right" data-tooltip="District Users">
          <router-link to="/marketplace/district-user">
            <font-awesome-icon class="fa-fw" icon="users-cog" />
            <span class="page">School Staff</span>
          </router-link>
        </li>
        <li v-else-if="isSwa" class="page-link has-tooltip-right" data-tooltip="School Staff">
          <router-link title="School Staffs" to="/marketplace/school-staff">
            <font-awesome-icon class="fa-fw" icon="users-cog" />
            <span class="page">School Staff</span>
          </router-link>
        </li>
        <li v-else-if="isStf" class="page-link has-tooltip-right" data-tooltip="Students">
          <router-link to="/marketplace/students">
            <font-awesome-icon class="fa-fw" icon="users-cog" />
            <span class="page">Students</span>
          </router-link>
        </li>
        <li v-if="isBiz" :class="'header is-uppercase '+isToggle">Business</li>
        <li v-if="isBiz" class="page-link has-tooltip-right" data-tooltip="Business List">
          <router-link title="Businesses" to="/marketplace/business">
            <font-awesome-icon class="fa-fw" icon="briefcase" />
            <span class="page">Businesses</span>
          </router-link>
        </li>
        <!--
        <li class="page-link" v-if="isEvnt">
          <a href="/marketplace/request-event">
            <i class="fas fa-users-cog fa-fw" aria-hidden="true"></i>
            <span :class="'page '+isToggle">Request Event</span>
          </a>
        </li>
        -->
        <li class="header is-uppercase">
          Operation
        </li>
        <li class="page-link has-tooltip-right" :data-tooltip="'Sign Out ' + appName">
          <LogOff location="sidebar" />
        </li>
      </ul>
      <!-- /.sidebar-menu -->
    </section>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
/***
 * vue components
 */
import { mapGetters } from "vuex";
import LogOff from "@/components/dynamic/LogOff"

export default {
  name: "SideMenu",
  components: {
    LogOff
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAM
    }
  },
  computed: {
    ...mapGetters(["isBiz", "isDwm", "isInt", "isMis", "isSad", "isStf", "isStu", "isSwa"]),
    ...mapGetters("show", ["isToggle"])
  },
  methods: {
    Signout() {
      this.$root.Logoff();
    },
  },
}
</script>

<style scoped>
.page {
  font-family: "Bwstretch", sans-serif;
}
</style>
