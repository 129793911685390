/***
 * push a second object into the first one
 *  */
export const objAttach = (obj1, obj2) => {
  let temp = objClone(obj1)
  for (let o in obj2) {
    temp[o] = obj2[o]
  }
  return temp
}
/***
 * duplicate object
 */
export const objClone = (obj, target = {}, prefix = "") => {
  for (let prop in obj) {
    target[prop] = obj[prop];
    if (prefix.length > 0) {
      target[prefix + prop] = obj[prop];
    }
  }
  return target
}
/***
 * check if the object is empty
 */
export const objEmpty = (obj) => {
  return (Object.keys(obj).length === 0 && obj.constructor === Object) ? true : false
}
/***
 * check if a specific key/value pair exists
 */
export const objExisted = (obj, key, value) => {
  for (const o in obj) {
    if (obj[o].hasOwnProperty(key) && obj[o][key].toString().toLowerCase() === value.toString().toLowerCase()) {
      return true
    }
  }
  return false
}
/***
 * check if two objects have exact the same properties, and these properties have same values
 */
export const objEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false
  }
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }
  return true
}
/***
 * filter selected object record with keywords
 */ 
export const objFilter = (obj, key, field) => {
  const Array = objToArray(obj);
  return Array.filter(record => {
    return !key || record[field].toLowerCase().indexOf(key.toLowerCase()) > -1
  })
}
/***
 * attach each att property to org object
 */
export const objPush = (org, att) => {
  for (const obj in att) {
    org[obj] = att[obj]
  }
  return org
}
/***
 * sort object by selected property
 */
export const objSort = (obj, prop, ops) => {
  if ( ops !== "asc") {
    return obj.sort((a, b) => (a[prop] < b[prop]) ? 1 : -1);
  }
  else {
    return obj.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1);
  }
}
/***
 * convert an object to array
 */
export const objToArray = (obj) => {
  return Object.keys(obj).map(i => obj[i]);
}
/***
 * generate dropdown options from existing data
 */
export const objToDropdown = (data = { count: 0 }, id, name, extra = false) => {
  let array = [];
  if (typeof data.count !== "undefined" && +data.count > 0) {
    for(let i = 0; i < data.count; i++) {
      array[i] = {
        id: data.errmsg[i][id],
        name: data.errmsg[i][name]
      };
      if (extra) {
        array[i][extra] = data.errmsg[i][extra];
      }
    }
  }
  return array;
}