<template>
  <dl>
    <dt class="is-sr-only">
      Link to Career Representative
    </dt>
    <dd :class="cssLinkDd">
      <router-link :class="cssLinkClass" to="/business/career-representatives">
        Career Representative
      </router-link>
    </dd>
    <dt class="is-sr-only">
      Link to Partners
    </dt>
    <dd :class="cssLinkDd">
      <router-link :class="cssLinkClass" to="/business/partners">
        Partners
      </router-link>
    </dd>
    <dt class="is-sr-only">
      Link to Business Resources
    </dt>
    <dd :class="cssLinkDd">
      <router-link :class="cssLinkClass" to="/business/business-resources">
        Business Resources
      </router-link>
    </dd>
    <dt class="is-sr-only">
      Link to Business Sign-Up
    </dt>
    <dd :class="cssLinkDd">
      <router-link class="navbar-item navbar-sublink close-nav is-hidden-desktop" to="/become-career-representative">
        Business Sign-Up
      </router-link>
    </dd>
    <dt class="is-sr-only">
      Link to Business Sign-Up
    </dt>
    <div :class="[cssLinkDd, cssLinkHide]">
      <router-link class="button is-business" :class="cssSignupClass" to="/become-career-representative">
        Business Sign-Up
      </router-link>
    </div>
  </dl>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
  isNavbar: {
    type: Boolean,
    default: false
  }
})

const cssLinkClass = computed(() => {
  return (props.isNavbar) ? "navbar-item navbar-sublink close-nav" : "banner-menu-link has-text-white"
})

const cssSignupClass = computed(() => {
  return (props.isNavbar) ? "navbar-sublink" : "is-signup"
})

const cssLinkDd = computed(() => {
  return (props.isNavbar) ? "" : "mb-2"
})

const cssLinkHide = computed(() => {
  return (props.isNavbar) ? "navbar-item is-no-underline is-hidden-touch mt-4" : ""
})
</script>

<style lang="scss" scoped>
@import "@/assets/style/navbar.scss";
</style>