<template>
  <div class="has-background-grey-dark is-version-alert">
    <div class="container has-text-light level">
      <p class="level-item">
        New version {{ version }} has been pushed live. Please cache refresh in your web browser to see the latest update.
      </p>
      <div class="level-item">
        <button class="button is-warning" @click="onRefresh">
          Refresh page
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VersionAlert",
  props: {
    version: {
      type: String,
      default: "0.0.0"
    }
  },
  methods: {
    onRefresh() {
      window.location.reload()
      localStorage.setItem("curVersion", this.version)
    }
  }
}
</script>

<style scoped>
.is-version-alert {
  bottom: 0;
  left: 0;
  padding: 10px 0;
  position: fixed;
  right: 0;
  z-index: 100
}
</style>