const state = () => ({
  Messages: 0,
  Tasks: 0
})

const mutations = {
  // update notify values
  updNotify(state, data) {
    state[data.cat] = data.value
  },
}

export default {
  namespaced: true,
  state,
  mutations
}
