<template>
  <div class="load-fullscreen">
    <div class="load-dialog is-size-5">
      <font-awesome-icon class="fa-spin" icon="spinner" />
      Loading...
    </div>
  </div>
</template>

<style lang="scss" scoped>
.load-fullscreen {
  background-color: rgba(0,0,0,0.7);
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  .load-dialog {
    bottom: 0;
    color: #ddd;
    display: block;
    font-weight: bold;
    left: 0;
    padding: 20px 20px 50px 10%;
    position: fixed;
    margin: auto;
    text-align: left;
    width: 100%;
    z-index: 1001;
  }
}
</style>