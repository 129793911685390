<template>
  <dl>
    <dt class="is-sr-only">
      Link to I'm a Student
    </dt>
    <dd :class="cssLinkDd">
      <router-link :class="cssLinkClass" to="/education/i-am-a-student">
        I'm a Student
      </router-link>
    </dd>
    <dt class="is-sr-only">
      Link to Teachers &amp; Parents
    </dt>
    <dd :class="cssLinkDd">
      <router-link :class="cssLinkClass" to="/education/teachers-and-parents">
        Teachers &amp; Parents
      </router-link>
    </dd>
    <dt class="is-sr-only">
      Link to Education Resources
    </dt>
    <dd :class="cssLinkDd">
      <router-link :class="cssLinkClass" to="/education/education-resources">
        Education Resources
      </router-link>
    </dd>
    <dt class="is-sr-only">
      Link to Student/Educator Sign-Up
    </dt>
    <dd :class="cssLinkDd">
      <router-link class="navbar-item navbar-sublink close-nav is-hidden-desktop" to="/register">
        Student/Educator Sign-Up
      </router-link>
    </dd>
    <dt class="is-sr-only">
      Link to Student/Educator Sign-Up
    </dt>
    <dd :class="[cssLinkDd, cssLinkHide]">
      <router-link class="button is-dark" :class="cssSignupClass" to="/register">
        Student/Educator Sign-Up
      </router-link>
    </dd>
  </dl>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
  isNavbar: {
    type: Boolean,
    default: false
  }
})

const cssLinkClass = computed(() => {
  return (props.isNavbar) ? "navbar-item navbar-sublink close-nav" : "banner-menu-link has-text-dark"
})

const cssSignupClass = computed(() => {
  return (props.isNavbar) ? "navbar-sublink" : "is-signup"
})

const cssLinkDd = computed(() => {
  return (props.isNavbar) ? "" : "mb-2"
})

const cssLinkHide = computed(() => {
  return (props.isNavbar) ? "navbar-item is-no-underline is-hidden-touch mt-4" : ""
})
</script>

<style lang="scss" scoped>
@import "@/assets/style/navbar.scss";
</style>