<template>
  <nav class="navbar is-flex is-justfy-center top-navbar" :class="[isNavBar, hasTheme]" role="navigation" aria-label="main navigation is-primary">
    <a v-if="isLoggedIn && isInternal" class="navbar-item navbar-internal-toggle is-flex-touch" @click="toggleMenu">
      <font-awesome-icon icon="bars" />
    </a>
    <div class="navbar-brand">
      <a v-if="getUser && isInternal" class="navbar-item app-name" href="/" title="Go to Front Page">
        <img
          class="has-image-centered logo"
          :src="imageSrc + '/img/brand-bea-horizontal.webp'"
          alt="Business & Education Alliance Logo"
        >
      </a>
      <Brand v-else />
      <a
        v-if="!isInternal"
        role="button"
        class="navbar-burger burger csr-ptr"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbar"
        @click="toggleMenu"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>
    <div id="navbar" class="navbar-menu" :class="{'is-active': !isToggle}">
      <div v-if="getUser" class="navbar-end is-hidden-touch">
        <router-link to="/marketplace/profile" class="navbar-item close-nav is-hidden-touch">
          <font-awesome-icon class="fa-fw mr-1" icon="user" /> {{ getUserFullName }}
        </router-link>
        <!-- <router-link to="/tasks/active-task" class="navbar-item close-nav is-hidden-touch">
          <font-awesome-icon class="fa-fw" icon="tasks" />
          <span class="tag tag-notif" :class="hasTasks">{{ getTasksCount }}</span>
        </router-link> -->
        <LogOff class="is-hidden-touch" location="navbar" />
      </div>
      <div v-else class="navbar-end">
        <div class="navbar-item dropdown-education has-dropdown is-hoverable">
          <a class="navbar-link">
            Education
          </a>
          <div class="navbar-dropdown is-right">
            <div class="navbar-item is-no-underline is-hidden-touch">
              <BannerEdu />
            </div>
            <LinkStu :is-navbar="true" />
          </div>
        </div>
        <div class="navbar-item dropdown-business has-dropdown is-hoverable">
          <a class="navbar-link">
            Business
          </a>
          <div class="navbar-dropdown is-right">
            <div class="navbar-item is-no-underline is-hidden-touch">
              <BannerBiz />
            </div>
            <LinkBiz :is-navbar="true" />
          </div>
        </div>
        <router-link class="navbar-item" title="Log In" to="/login">
          Sign In
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import BannerBiz from "@/components/static/BannerBiz"
import BannerEdu from "@/components/static/BannerEdu"
import Brand from "@/components/static/Brand"
import LinkBiz from "@/components/static/LinkBiz"
import LinkStu from "@/components/static/LinkStu"
import LogOff from "@/components/dynamic/LogOff"
/***
 * custom libraries
 */
import { ajaxCall, ajaxCompare } from "@/utils/ajax"
import { getAuthData } from "@/utils/user"
import { textCapFirst } from "@/utils/text"


export default {
  name: "NavBar",
  components: {
    BannerBiz,
    BannerEdu,
    Brand,
    LinkBiz,
    LinkStu,
    LogOff
  },
  data() {
    return {
      appCounters: null,
      imageSrc: process.env.VUE_APP_CLOUDFRONT,
    }
  },
  computed: {
    ...mapGetters(["getUser", "getUserFullName"]),
    ...mapGetters("show", ["isToggle"]),
    ...mapState({
      getTasksCount: state => state.notify.Tasks,
    }),
    hasTheme() {
      let path = this.$store.state.theme.NavBar;
      let temp = "";
      switch(path) {
        case "stu":
          temp = "is-stu";
          break;
        case "biz":
          temp = "is-biz"
          break;
        default:
          temp = ""
      }
      return temp
    },
    hasTasks() {
      return (parseInt(this.getTasksCount) > 0) ? "is-danger" : "is-success"
    },
    isInternal() {
      return this.$store.state.isInternal || false
    },
    /***
     * switch styles between internal and external navgation bar
     */
    isNavBar() {
      return (this.getUser && this.isInternal) ? "has-shadow" : "is-fixed-top"
    },
    isLoggedIn() {
      return (this.getUser) ? true : false
    },
    // Notify() {
    //   return this.$store.state.Notify
    // },
  },
  mounted() {
    this.closeNav();
    // Add a listener to close mobile navbar drop down menu
    document.getElementById("navbar")
      .querySelectorAll(".has-dropdown")
      .forEach(el => {
        el.addEventListener("click", () => {
          let menu = el.querySelector(".navbar-dropdown")
          menu.style.display = "none"
          setTimeout(() => {
            el.blur()
            // Reset the display property to its original state, so the menu can appear again next time
            menu.style.display = ""
          }, 200)
        })
      })
    //this.init()
  },
  unmounted() {
    this.appCounters = null
  },
  methods: {
    /***
     * initial data fetching
     */
    init() {
      this.appCounters = window.setInterval(() => {
        if (this.getUser) {
          this.fetchCounts()
        }
      }, 60000)
    },
    // automatically close navbar dropdown after click an link item
    closeNav() {
      const navBar = document.getElementById("navbar");
      if (navBar) {
        navBar
          .querySelectorAll(".close-nav")
          .forEach(el => {
            el.addEventListener("click", () => {
              setTimeout(function() {
                this.toggleMenu()
              }.bind(this), 10)
            })
          })
      }
    },
    /***
     * fetch alert counts
     */
    fetchCounts() {
      ajaxCall({
        data: getAuthData({
          api: "cnt",
          method: "Fetch"
        }),
        url: "api"
      }, result => {
        if (ajaxCompare(result.errno, [1])) {
          for (let obj in result.notify) {
            if (ajaxCompare(result.notify[obj].errno, [1])) {
              this.$store.commit(
                "notify/updNotify",
                {
                  cat: textCapFirst(obj),
                  value: result.notify[obj].errmsg.count
                }
              )
            }
          }
        }
      })
    },
    // toggle navbar/side menu
    toggleMenu() {
      this.$store.commit("show/UpdShow", {
        cat: "sideMenu",
        value: !this.isToggle
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/navbar.scss";

.logo {
  height: 28px;
}
.navbar, .navbar .navbar-item:hover, .navbar .navbar-item:focus {
  @extend .has-background-grey-default;
  color: $color-darker
}
.navbar.is-biz {
  background-color: $color-biz !important;
  .navbar-burger {
    color: white
  }
}
.navbar.is-stu, .navbar.is-stu .navbar-item:hover, .navbar.is-stu .navbar-item:focus {
  background-color: $color-student !important;
}
.navbar .navbar-item:hover, .navbar .navbar-item:focus {
  text-decoration: underline
}

.navbar.top-navbar {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.is-flex-touch.is-desktop-toggle {
  display: none !important;
  justify-content: center;
  width: 3.25rem;
}
.navbar.has-shadow {
  background-color: #eee;
}
.navbar-item.navbar-internal-toggle {
  align-items: center
}
@media screen and (min-width: 1087px) {
  .is-flex-touch.is-desktop-toggle {
    display: flex !important;
  }
}

</style>
