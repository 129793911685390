<template>
  <footer role="contentinfo">
    <div class="columns m-0">
      <div class="column split split-left">
        <img src="@/assets/img/icon-calendar.svg" loading="lazy" width="100" alt="" class="calendar-img">
        <div>
          <h2 class="prefooter-heading">{{ appName }} Events!</h2>
          <p class="prefooter-p">Find scheduled career exploration events and activities in the Pikes Peak Region.</p>
          <a class="button-event" href="/ppbea-events">
            Visit Events Page <font-awesome-icon class="fa-fw" icon="arrow-right" />
          </a>
        </div>
      </div>
      <div class="column split split-right">
        <div role="form" class="form" aria-label="Subscribe form">
          <h2 class="prefooter-heading">{{ appName }} Newsletter</h2>
          <p class="prefooter-p">Leave your Email address below to receive the latest news right in your inbox.</p>
          <div class="newsletter-form">
            <label class="is-sr-only" for="subscribe-email">
              Enter email address to subscribe CCL Marketplace Newsletter
            </label>
            <div class="field newsletter-input has-addons">
              <div class="control is-expanded">
                <input
                  id="subscribe-email"
                  v-model="form.email"
                  class="input"
                  type="text"
                  placeholder="Enter Email for Updates"
                  autocomplete="email"
                  aria-label="Email address"
                >
              </div>
              <div class="control">
                <button class="button" type="button" @click="Subscribe">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
        <img class="envelope-img" src="@/assets/img/icon-envelope.svg" loading="lazy" width="128" alt="">
      </div>
    </div>
    <div class="has-background-grey-default">
      <p class="container py-5 is-size-5">
        We are committed to ADA compliance and are actively working to perfect our site under ADA guidelines. If you encounter any issues, please <router-link to="/contact-us/bug-report" title="Bug Report Form">click here</router-link> to send us a bug report.
      </p>
    </div>
    <div class="footer has-background-black-ter has-text-white">
      <div class="container">
        <div class="footer-row">
          <div class="footer-left">
            <router-link to="/" aria-current="page" class="footer-logo">
              <img :src="imageSrc + '/img/bea-logo-dark-tm.webp'" width="140" alt="Logo of Business & Education Alliance">
            </router-link>
            <p class="tagline mb-5">
              Business &amp; Education Alliance
            </p>
            <p class="text-large">
              Colorado Springs, CO
            </p>
          </div>
          <div class="footer-right pt-2">
            <div v-for="(l, idx) in links" :key="idx" class="footer-menu-column">
              <h6 :id="'footer-' + idx" class="all-caps-heading is-uppercase has-text-yellow">
                {{ idx }}
              </h6>
              <ul class="link-list w-list-unstyled" role="list">
                <li v-for="a in l" :key="a.url" class="footer-item">
                  <router-link class="footer-link has-text-white-ter" :data-tooltip="a.title" :to="a.url">
                    {{ a.title }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-lower-row">
          <ul role="list" class="social-icons-list">
            <li v-for="s in msm" :key="s.label" class="list-item">
              <a class="social-link" :class="s.class" :href="s.url" rel="noopener" target="_blank" :title="appName + ' ' + s.label">
                <font-awesome-icon class="fa-fw" :icon="['fab', s.icon]" />
              </a>
            </li>
          </ul>
          <div>
            <p>
              Copyright <font-awesome-icon icon="copyright" /> 2021 - {{ new Date().getFullYear() }} <a class="has-text-grey-light" data-tooltip="The Work-based Learning MARKETPLACE" href="https://www.businessandeducationalliance.org/" title="Business and Education Alliance" target="_blank">BEA</a>. All rights reserved.
              <a class="has-text-grey-light" href="/version-updates" title="Version Update History">
                v {{ appVersion }}
              </a>
            </p>
            <p>
              Powered by <a class="has-text-grey-light" href="https://outraverse.io" title="Outraverse LLC" target="_blank">Outraverse</a>.
            </p>
          </div>
        </div>
      </div>
      <router-link v-if="!getUser" to="/login" class="link-sign-in">
        Sign-In
      </router-link>
    </div>
  </footer>
</template>

<script>
/***
 * vue components/libraries
 */
import { mapGetters, mapState } from "vuex";
/***
 * custom libraries
 */
import { ajaxCall, ajaxCompare, ajaxErrShow } from "@/utils/ajax";
import { objClone } from "@/utils/object";
/***
 * third-party libraries
 */
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import { email, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const formData = {
  api: "subs",
  email: "",
  method: "Add",
}

export default {
  name: "FooterView",
  setup () {
    // useVuelidate()
    const v$ = useVuelidate();
    return {
      v$
    };
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      form: {
        alert: true,
        api: "subs",
        email: "",
        method: "Add",
      },
      imageSrc: process.env.VUE_APP_CLOUDFRONT,
      links: {
        education: [
          { title: "I'm a Student", url: "/education/i-am-a-student" },
          { title: "Teachers & Parents", url: "/education/teachers-and-parents" },
          { title: "Education Resources", url: "/education/education-resources" },
          { title: "Public Events", url: "/ppbea-events" },
          { title: "Student/Educator Sign-Up", url: "/register"},
        ],
        business: [
          { title: "Career Representatives", url: "/business/career-representatives" },
          { title: "Advisories", url: "/business/advisories" },
          { title: "Partners", url: "/business/partners" },
          { title: "Business Resources", url: "/business/business-resources" },
          { title: "Business Sign-Up", url: "/become-career-representative" }
        ],
        "CCL MARKETPLACE": [
          { title: "About Us", url: "/about-us" },
          { title: "FAQ", url: "/frequently-asked-questions" },
          { title: "Legal", url: "/terms-and-conditions" },
          { title: "Research & Media", url: "/research-and-media" },
          { title: "Contact Us", url: "/contact-us" },
        ]
      },
      msm: [
        { label: "LinkedIn", class:"social-linkedin", icon: "linkedin", url: "https://www.linkedin.com/company/ppbea/about/" },
        { label: "Facebook", class:"social-facebook", icon: "facebook-f", url: "https://www.facebook.com/BEA.BusinessEducationAlliance" },
        { label: "Twitter", class:"social-twitter", icon: "twitter", url: "https://twitter.com/BizEdAlliance" },
        { label: "Instagram", class:"social-instagram", icon: "instagram", url: "https://www.instagram.com/businesseducationalliance/" },
        { label: "YouTube", class:"social-youtube", icon: "youtube", url: "https://www.youtube.com/channel/UC7EVhnOaWGEcJHa_iJIVIuw" },
        { label: "Pinterest", class:"social-pinterest", icon: "pinterest", url: "https://www.pinterest.com/PPBEA_/" }
      ],
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    ...mapState({
      appVersion: state => state.appVersion,
    })
  },
  mounted() {
    this.resetSubForm()
  },
  methods: {
    /***
     * reset subscribe form
     */
    resetSubForm() {
      this.form = objClone(formData)
    },
    /***
     * subscribe ppbea newsletter
     */
    async Subscribe() {
      this.v$.form.$touch();
      if (!this.v$.form.$error) {
        this.form.email = this.form.email.toLowerCase();
        await this.$recaptchaLoaded()
        // Execute reCAPTCHA with action "subscribe".
        this.form.recaptacha = await this.$recaptcha("subscribe")
        ajaxCall({
          data: this.form,
          url: "api"
        }, result => {
          let err = ajaxErrShow(result);
          if (ajaxCompare(result.errno, [-1])) {
            this.resetSubForm()
          }
          createToast(err.message, {
            showIcon: true,
            type: err.status,
            position: "bottom-right"
          })
        });
      }
      else {
        createToast("Please provide a correct email address.", {
          showIcon: true,
          type: "danger",
          position: "bottom-right"
        });
      }
    }
  },
  validations() {
    return {
      form: {
        email: {required, email},
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";

.all-caps-heading {
  font-family: Bwstretch, sans-serif;
  font-size: 30px;
}
.button-event {
  border-radius: 6px;
  background-color: $color-sky;
  color: #fff;
  font-family: 'Bwstretch Book', sans-serif;
  font-size: 24px;
  height: 50px;
  padding: 8px 18px;
  position: relative;
  -webkit-transition: box-shadow 300ms ease, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, box-shadow 300ms ease;
  transition: transform 300ms ease, box-shadow 300ms ease, -webkit-transform 300ms ease;
}
.button-event:hover {
  -webkit-transform: translate(0px, -4px);
  -ms-transform: translate(0px, -4px);
  transform: translate(0px, -4px);
}
.footer {
  position: relative;
  padding-top: 96px;
  padding-bottom: 40px;
}
.footer-left {
  width: 40%;
}
.footer-link {
  font-size: 18px
}
.footer-lower-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
}
.footer-menu-column {
  margin-right: 20px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.footer-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.footer-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 96px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.link-list {
  display: -ms-grid;
  display: grid;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 6px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.link-sign-in {
  bottom: auto;
  color: #fff;
  display: inline-block;
  font-family: Bwstretch, sans-serif;
  font-size: 23px;
  left: auto;
  padding-right: 30px;
  padding-left: 0px;
  position: absolute;
  right: 0%;
  text-decoration: underline;
  top: 20px;
  white-space: nowrap;
}
.newsletter-form {
  margin: 0 0 15px;
  .newsletter-input {
    border: 3px solid $color-sky;
    border-radius: 4px;
    .button {
      background-color: $color-sky;
      border: none;
      border-radius: 0;
      color: #fff;
      font-family: "Bwstretch Book", sans-serif;
    }
  }
}
.prefooter-heading {
  font-family: Bwstretch, sans-serif;
  font-size: 48px;
  line-height: 1;
  margin-top: 0px;
  margin-bottom: 24px;
}
.prefooter-p {
  font-size: 20px;
  max-width: 420px;
  margin-bottom: 60px;
}
.social-icons-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -4px;
  margin-left: -4px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  .social-link {
    color: $color-grey-lighter;
    margin-right: 5px;
  }
  .social-facebook:hover {
    color: #3b5998
  }
  .social-instagram:hover {
    color: #f77737
  }
  .social-linkedin:hover {
    color: #007bb5
  }
  .social-pinterest:hover {
    color: #bd081c
  }
  .social-youtube:hover {
    color: #ff0000
  }
  .social-twitter:hover {
    color: #1da1f2
  }
}
.split {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 80px 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.split-left {
  background-color: #e6e6e6;
  .calendar-img {
    width: 80px;
    margin-right: 40px;
    margin-bottom: -40px;
  }
}
.split-right {
  @extend .has-background-grey-default;
}
.tagline {
  font-size: 20px;
  font-weight: 700;
}
.text-large {
  font-size: 18px;
  line-height: 1.2;
}
@media screen and (max-width: 991px) {
  .footer-left {
    width: 50%;
    margin-bottom: 48px;
  }
  .footer-lower-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .footer-menu-column {
    margin-right: 24px;
  }
  .footer-row {
    margin-bottom: 72px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .footer-menu-column {
    width: 50%;
    margin-right: 8px;
    margin-bottom: 36px;
    margin-left: 0px;
  }
  .social-icons-list {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 479px) {
  .footer-menu-column {
    width: 100%;
    margin-bottom: 24px;
  }
}
</style>