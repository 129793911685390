/***
 * capitalize the first letter of the slected word
 */
export const cap1stLetter = (data, ops) => {
  const words = data.split(ops);
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }
  return words.join(ops)
}
/***
 * capitalize first letter of every word in a string
 */
export const capEveryWord = (data) => {
  return data
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
/***
 * check if a text contains only numbers
 */
export const containsOnlyNum = (data) => {
  // Create a regular expression pattern that matches only numeric digits
  const numericRegex = /^[0-9]+$/;
  // Use the test() method of the regular expression to check if the value matches the pattern
  return numericRegex.test(data);
}
/***
 * generate a sequence of random numbers
 */
export const genRandomText = (length) => {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.@#$%";
  let temp = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    temp += charset.charAt(Math.floor(Math.random() * n))
  }
  return temp;
}
/***
 * modify page title string from web url to regular title
 */
export const getDynPageTitle = (data) => {
  return data.replaceAll("-", " ").toLowerCase();
}
/***
 * modify page title string to web url by replacing space with dash, and lower all cap letters
 */
export const getDynPageUrl = (url) => {
  return url.replace(/\s+/g, '-').toLowerCase();
}
/***
 * update web url, add http if without it
 */
export const gotoUrl = (url) => {
  if(url !== null && url.length >= 4 && url.substr(0, 4) !== "http") {
    url = "http://" + url;
  }
  return url
}
/***
 * check if a string is null
 */
export const isNull = (data) => (data === null ? "" : data);
/***
 * Show/hiden password
 */ 
export function showPasswd(type) {
  return (type === "password") ? "text" : "password"
}
/***
 * remove prefix from object properties if matach
 */
export const slicePrefix = (data, key, position) => {
  let temp = {}
  for (let obj in data) {
    if (obj.startsWith(key)) {
      temp[obj.slice(position)] = data[obj]
    }
  }
  return temp
}
/***
 * strip html tags from a string of text
 */
export const stripTags = (string) => {
  let elem = document.createElement("div");
  elem.innerHTML = string;
  return elem.textContent
}
/***
 * capitalize the first letter of a string
 */
export const textCapFirst = (data) => {
  return data.charAt(0).toUpperCase() + data.slice(1)
}
/***
 * display character count of textarea
 */ 
export const textCount = (text, max) => {
  let count = 0
  if (typeof text !== "undefined" && text !== null) {
    count = text.length
  }
  else {
    count = 0
  }
  return count + " / " + max
}
/***
 * fetch if a substring is included in selected string
 */
export const textInclude = (data, key) => {
  if (typeof data === "undefined" || data === null || data === "") {
    return false
  }
  else {
    data = data.toString().toLowerCase()
    return data.includes(key.toLowerCase())
  }
}
/***
 * convert a string to an array
 */
export const textToArray = (data, separator) => {
  return data.split(separator)
}
/***
 * convert line break to <br>
 */
export const textToLinebreak = (data) => {
  return (data !== "") ? data.replace(/(?:\r\n|\r|\n)/g, "<br>") : "";
}
